<script setup lang="ts">
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'

const emit = defineEmits<Emit>()

interface Emit {
  (e: 'update:isDrawerOpen', value: boolean): void
}

const setting = () => {
  emit('update:isDrawerOpen', true)
}

const ability = useAbility()
const userData = useCookie<any>('userData')
const userAbilityRules = useCookie<any>('userAbilityRules')
const accessToken = useCookie<any>('accessToken')

const avatar = computed(() => {
  return userData.value.avatar ? useRuntimeConfig().public.apiBaseUrl + /storage/ + userData.value.avatar : ''
})

const { signOut } = useAuth()

async function logout() {
  try {
    await signOut({ redirect: false })

    // Remove cookie
    userData.value = null
    userAbilityRules.value = null
    accessToken.value = null

    // Reset user abilities
    ability.update([])

    navigateTo({ name: 'login' })
  }
  catch (error) {
    throw createError(error)
  }
}

const userProfileList = [
  { type: 'divider' },
  { type: 'navItem', icon: 'tabler-user', title: 'プロフィール', to: { name: 'pages-account-settings-tab', params: { tab: 'account' } } },

  { type: 'navItem', icon: 'tabler-settings', title: '設定', onClick: setting },
  { type: 'divider' },
  { type: 'navItem', icon: 'tabler-lifebuoy', title: 'ヘルプ', to: { name: 'help' } },
  { type: 'navItem', icon: 'tabler-logout', title: 'ログアウト', onClick: logout },
]

const iconRole = (role: string) => {
  switch (role) {
    case 'admin':
      return 'tabler-letter-a'
    case 'client':
      return 'tabler-letter-u'
    case 'owner':
      return 'tabler-letter-t'
  }
}
</script>

<template>
  <VAvatar
    v-if="userData"
    class="cursor-pointer"
    variant="tonal"
  >
    <VImg
      v-if="avatar"
      :src="avatar"
    />
    <VIcon
      v-else
      :icon="iconRole(userData.role)"
    />

    <VMenu
      activator="parent"
      width="230"
      location="bottom end"
      offset="14px"
    >
      <VList>
        <VListItem>
          <VListItemTitle class="font-weight-medium">
            <div class="text-wrap">
              {{ userData.name }}
            </div>
            <div v-if="userData.role === 'client'">
              <VChip label>
                {{ userData.client_store.role }}
              </VChip>
            </div>
          </VListItemTitle>
        </VListItem>

        <PerfectScrollbar :options="{ wheelPropagation: false }">
          <template
            v-for="item in userProfileList"
            :key="item.title"
          >
            <VListItem
              v-if="item.type === 'navItem'"
              :to="item.to"
              @click="item.onClick && item.onClick()"
            >
              <template #prepend>
                <VIcon
                  class="me-2"
                  :icon="item.icon"
                  size="22"
                />
              </template>

              <VListItemTitle>{{ item.title }}</VListItemTitle>

              <template
                v-if="item.badgeProps"
                #append
              >
                <VBadge v-bind="item.badgeProps" />
              </template>
            </VListItem>

            <VDivider
              v-else
              class="my-2"
            />
          </template>
        </PerfectScrollbar>
      </VList>
    </VMenu>
  </VAvatar>
</template>
